$(function() {
  AOS.init();
});

$(document).ready(function(){
    $("a.fancybox, .gallery-item .gallery-icon a").attr('data-fancybox','postsgallery').fancybox({
        'overlayOpacity' : 0.1,
        'overlayColor' : '#111',
        'hideOnOverlayClick' : 'true',
        'enableEscapeButton' : 'true',
        'showCloseButton' : false,
        'titleShow' : 'false',
        'showNavArrows' : true
    });
});
