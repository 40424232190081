import 'jquery'
import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import 'popper.js'
import '@fortawesome/fontawesome-free/js/all'
import AOS from 'aos'
global['AOS'] = AOS
import 'aos/dist/aos.css'
import '../sass/style.scss'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import '../js/custom'
